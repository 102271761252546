'use strict';
var BaseDir   = '/';

function ajaxCSRF() {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
}

function url(url){
    return BaseDir + url;
}

function sidebar() {
    var sidebar = document.getElementById('sidebar');

    if(sidebar === null) {
        return false;
    }

    var content = document.getElementById('content'),
        $sidebarHeight = $('#sidebar ul li.group').length * 45;

    $('.sidebar-dropdown').each(function () {
        $sidebarHeight += ($(this).outerHeight());
    });

    content.style.minHeight = $sidebarHeight + 'px';

    var $sidebar = $(sidebar),
        $element = $sidebar.find('a.group-item');

    $element.on('click', function (e) {
        e.preventDefault();

        var $group = $(this).closest('li'),
            $triangle = $(this).find('.triangle'),
            $chevron = $group.find('.chevron');

        if ($group.hasClass('open')) {
            $triangle.removeClass('glyphicon-triangle-bottom')
                .addClass('glyphicon-triangle-right');

            $chevron.removeClass('glyphicon-chevron-up')
                .addClass('glyphicon-chevron-down');

            $group.removeClass('open');
        } else {
            $triangle.removeClass('glyphicon-triangle-right')
                .addClass('glyphicon-triangle-bottom');

            $chevron.removeClass('glyphicon-chevron-down')
                .addClass('glyphicon-chevron-up');

            $group.addClass('open');
        }
    });

    $('header button.menu-collapse').on('click', function () {
        if ($sidebar.hasClass('collapse')) {
            $sidebar.removeClass('collapse')
        } else {
            $sidebar.addClass('collapse')
        }
    });
}

function tooltips(){
    $('[data-toggle="tooltip"]').tooltip({
        container: 'body',
        trigger: 'hover'
    });
}

function submitedForm() {
    var $content = $('#content'),
        $blockContent = $content.find('#reload-content'),
        $container    = $blockContent.closest('.island'),
        $loader       = $('.cssload-container');

    $content.on('click', 'button[type="submit"]', function (e) {
        var $form = $(this).parents('form');

        $content.find('.alert').remove();

        $container.addClass('height-200').addClass('w-100');
        $blockContent.addClass('hidden');
        $loader.removeClass('hidden');
        $container.removeClass('hidden');

        $.post($form.attr('href'), $form.serialize(), function (request) {
            switch (request.type) {
                case 'alert':
                    $content.find('.page-header').after(request.content);
                    break;
                case 'redirect':
                    window.location.href = request.content;
                    break;
                case 'content':
                    $blockContent.html(request.content);
                    $blockContent.trigger('submit.form');
            }
            $loader.addClass('hidden');
            $container.removeClass('height-200').removeClass('w-100');
            $blockContent.removeClass('hidden');
        }, 'json');

        e.preventDefault();
    });
}

function randomString(length) {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for( var i=0; i < length; i++ )
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
}

function islandWidget() {
    $('.page-content .island .header button').on('click', function (e) {
        var $element = $(this);
        switch ($element.data('widget')) {
            case 'collapse':
                var $content = $element.parents('.island').find('.content');

                if ($content.hasClass('collapsed')) {
                    $content.removeClass('collapsed');
                    $content.slideDown();
                    $element.find('i')[0].className = 'glyphicon glyphicon-minus';
                } else {
                    $content.addClass('collapsed');
                    $content.slideUp();
                    $element.find('i')[0].className = 'glyphicon glyphicon-plus';
                }
                break;
        }
    });
}

function scrollFix() {
    var srollfix = function (width) {
        $('div.scroll-fix').css({width: width - 48});
    };

    srollfix(window.innerWidth);

    window.onresize = function () {
        srollfix(window.innerWidth);
    }
}

//fixme!
function getCookie(name) {
    var matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    var coocke = matches ? decodeURIComponent(matches[1]) : undefined;
    if(coocke === undefined){
        return coocke;
    }
    return coocke.split('~')[1];
}

function lang(key) {
    var cookieLocale = getCookie('locale'),
        locale = cookieLocale === undefined ? 'ru' : cookieLocale;

    return localizationObject[locale][key];
}

function setCookie(name, value, options) {
    options = options || {};
    var expires = options.expires;
    if (typeof expires == "number" && expires) {
        var d = new Date();
        d.setTime(d.getTime() + expires * 1000);
        expires = options.expires = d;
    }
    if (expires && expires.toUTCString) {
        options.expires = expires.toUTCString();
    }

    value = encodeURIComponent(value);
    var updatedCookie = name + "=" + value;

    for (var propName in options) {
        updatedCookie += "; " + propName;
        var propValue = options[propName];
        if (propValue !== true) {
            updatedCookie += "=" + propValue;
        }
    }

    document.cookie = updatedCookie;
}

(function($) {
    $.fn.goTo = function() {
        $('html, body').animate({
            scrollTop: $(this).offset().top + 'px'
        }, 'fast');
        return this;
    }
})(jQuery);

$(document).ready(function(){
    ajaxCSRF();
    sidebar();
    tooltips();
    submitedForm();
    islandWidget();
    scrollFix();
});